import {Injectable} from '@angular/core';
import {BaseRepository} from './base-repository';
import {Observable} from 'rxjs';
import {IPostTemplateReport, IReportPartParameters} from '@store/types';
import {IReportDesignerRequest} from '@shared/models/common-types';
import {IPathFile, IPostReliabilityReport, IReport} from './api.repository';
import {DocumentFormatEnums} from '@shared/constants/document-format.enums';
import {IPartialInterval} from '@shared/ui-components/n-inverval/models/interval.types';
import {
	IReportType,
	IReportTypePart,
	ITemplateReport
} from '../../modules/statistic/components/statistic-loading/components/report-designer/models/report-designer.type';
import {IStoreNomenclatureFilter} from '../../modules/nomenclature-store/models/nomenclature-interface';
import {EnumReports} from '@shared/components/filter/models/filter.enums';

@Injectable({providedIn: 'root'})
export class ReportRepository extends BaseRepository {
	public getAllReportTemplates(): Observable<ITemplateReport[]> {
		return this._http.get<ITemplateReport[]>(
			`${this._config.javaApiUrl}/report/user-report-template-parameter`
		);
	}

	public getReportTemplate(id: string): Observable<ITemplateReport> {
		return this._http.get<ITemplateReport>(
			`${this._config.javaApiUrl}/report/user-report-template-parameter/${id}`
		);
	}

	public createReportTemplate(body: IPostTemplateReport): Observable<any> {
		return this._http.post<any>(
			`${this._config.javaApiUrl}/report/user-report-template-parameter`,
			body
		);
	}

	public updateReportTemplate(id: number, body: IPostTemplateReport) {
		return this._http.put<any>(
			`${this._config.javaApiUrl}/report/user-report-template-parameter/${id}`,
			body
		);
	}

	public deleteReportTemplate(id: number): Observable<boolean> {
		return this._http.delete<boolean>(
			`${this._config.javaApiUrl}/report/user-report-template-parameter/${id}`
		);
	}

	public getReportParametersByPartIds(
		partIds: number[]
	): Observable<IReportPartParameters> {
		return this._http.post<IReportPartParameters>(
			`${this._config.javaApiUrl}/report/designer/part-parameters`,
			partIds
		);
	}

	public getReportByDesign(body: IReportDesignerRequest): Observable<string[]> {
		return this._http.post<string[]>(
			`${this._config.javaApiUrl}/report/jasper/`,
			body
		);
	}


	public getReportTypes(): Observable<IReportType[]> {
		return this._http.get<IReportType[]>(
			`${this._config.javaApiUrl}/report/designer/type`
		);
	}

	public getReportTypesParts(): Observable<IReportTypePart[]> {
		return this._http.get<IReportTypePart[]>(
			`${this._config.javaApiUrl}/report/designer/part`
		);
	}

	public getReport(data: IStoreNomenclatureFilter): Observable<IPathFile> {
		const format = 'excel';
		const url = `${this._config.javaApiUrl}/report/${format}/dto`;
		return this._http.post<IPathFile>(url, data);
	}

	public getFileWithTableTasks(
		data: IPostReliabilityReport
	): Observable<IPathFile> {
		const url = `${this._config.javaApiUrl}/report/excel/reliability/report`;

		return this._http.post<IPathFile>(url, data);
	}

	public createArchiveDetailsReport(
		interval: IPartialInterval,
		unitIds: number[],
		format: DocumentFormatEnums
	): Observable<IPathFile> {
		const url = `${this._config.javaApiUrl}/report/${format}/details`;

		return this._http.post<IPathFile>(url, {
			ids: unitIds,
			intervalDate: interval,
		});
	}

	public buildMessagesReport(
		interval,
		unitIds: number[],
		levels,
		format: DocumentFormatEnums
	): Observable<IReport> {
		const url = `${this._config.javaApiUrl}/report/${format}/messages`;

		return this._http.post<IReport>(url, {
			ids: unitIds,
			intervalDate: interval,
			filter: levels,
			period: 0,
		});
	}

	public getExcelReport(
		ids: number[],
		intervalDate: IPartialInterval,
		reportTypes: EnumReports[]
	): Observable<IPathFile> {
		return this._http.post<IPathFile>(
			`${this._config['javaApiUrl']}/report/excel/unit`,
			{
				ids,
				intervalDate,
				reportTypes,
			}
		);
	}
}
